.eachRow{
    display: flex;
    justify-content: space-between;
    height: 32px;
    align-items: center;
}
.eachRow:hover{
    background-color: aliceblue;
}
.date{
    width: 10%;
    padding: 0px 5px;
}
.inTime{
    width: 10%;
    
}
.outTime{
    width: 10%;
    
}
.status{
    width: 10%;
    
}
.approvalStatus{
    width: 18%;

}
.overtimeHours{
    width: 10%;
}
.flagsWrapper{
    width: 17%;
}
.actionsWrapper{
    width: 15%;
}

.actionsWrapper button{
    padding: 4px 14px;
    background-color: #ced4da;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border: 1px solid #212529;
    font-size: 0.8rem;

}