.homeWrapper {
    width: 100%;
}

.bodyWrapper {
    padding: 4vh 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;

}

.leftWrapper {
    width: 21%;
    padding: 20px 2%;
    position: fixed;
    top: 8vh;
}

.eachCardWrapper {
    background-color: #adb5bd;

}

.markTimeWrapper {
    padding: 10px 0px;
    border-radius: 8px;
    width: 100%;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.markBtnWrapper {
    text-align: center;
    padding: 16px 0px 4px;
}

.markBtnWrapper button {
    background-color: #343a40;
    color: #ffffff;
    padding: 10px 12px;
    width: 200px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border: 1px solid #dfdfdf;
    font-weight: 600;
    cursor: pointer;
}

.smallTxt {
    font-size: 0.8rem;
    font-weight: 600;
    text-align: center;
}

.centerWrapper {
    width: 50%;
    padding: 0px 25%;
}

.tempGap {
    height: 60vh;
}


.rightWrapper {
    width: 21%;
    padding: 20px 2%;
    position: fixed;
    top: 8vh;
    right: 0;
}

.eachCardWrapper {
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    padding: 10px;
}



@media only screen and (max-width: 600px) {
    .homeWrapper{
        height: 91svh;
    }
    .bodyWrapper{
        flex-direction: column;
        padding: 1svh 0px;
        height: 89svh;
    }
    .leftWrapper{
        position: relative;
        width: 96%;
        top: 0px;
        padding: 0px 2%;
    }
    .centerWrapper{
        width: 94%;
        padding: 0px 0%;
        border: 1px solid #dfdfdf;
        margin: 1svh 3%;
        border-radius: 16px;
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        -ms-border-radius: 16px;
        -o-border-radius: 16px;
        min-height: 70svh;
        /* max-height: 70svh; */
        overflow-y: scroll;
    }
    .rightWrapper{
        position: relative;
        width: 96%;
        top: 0px;
        padding: 10px 2% 200px;
        display: none;
    }
    .eachCardWrapper{
        position: fixed;
        left: 0px;
        bottom: 0px;
        width: 100%;
        padding: 0px;
        border-radius: 0px;
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        -ms-border-radius: 0px;
        -o-border-radius: 0px;
        z-index: 55;
    }
    .markBtnWrapper{
        padding: 4px 0px;
    }
    .markBtnWrapper button{
        padding: 14px 15px;
        width: 90%;
    }
    .tempGap{
        height: 20svh;
    }
    .smallTxt{
        display: none;
    }
}