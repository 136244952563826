.mainWrapper{
    width: 100%;
}
.mainContainer{
    width: 100%;
    display: flex;
    justify-content: space-between;

}
.mainHeader{
    height: 5vh;
    display: flex;
    justify-content: space-between;
    background-color: #343a3f34;
    align-items: center;
    padding: 0px 1.2%;
}
.atTitle{
    font-weight: 600;
    font-size: 0.9rem;
}
.viewModeInTable{
    padding: 10px 0px;
    font-weight: 500;
    font-size: 0.8rem;
}
.viewMode select{
    padding: 4px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    font-size: 0.7rem;
    font-weight: 600;
    cursor: pointer;
}



.contentWrapper{
    width: 70%;
    padding: 20px 2.5%;
}
.contentContainer{
    background-color: #343a3f34;
    width: calc(100% - 22px);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 10px;
}
.summaryWrapper{
    width: 20%;
    padding: 20px 2.5%;
}
.summaryContainer{
    width: calc(100% - 22px);
    border: 1px solid #343a3f;
    padding: 10px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    background-color: #495057;
}



.tableHeader{
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    height: 32px;
    background-color: #343a3f;
    align-items: center;
    color: white;
}

.date{
    width: 10%;
    padding: 0px 5px;
}
.inTime{
    width: 10%;
    
}
.outTime{
    width: 10%;
    
}
.status{
    width: 10%;
    
}
.approvalStatus{
    width: 18%;

}
.overtimeHours{
    width: 10%;
}
.flagsWrapper{
    width: 17%;
}
.actionsWrapper{
    width: 15%;
}

.summaryTitle{
    color: white;
    font-weight: 600;
}
.notes{
    text-align: justify;
    font-size: 0.9rem;
    font-weight: 500;
    padding: 10px 0px;
    color: rgba(255, 255, 255, 0.76);
}