.container {
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    background-color: #f9f9f9;
}

.title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

.policySection {
    margin-bottom: 20px;
    padding: 15px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.policyCategory {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #007bff;
}

.policyDescription {
    font-size: 14px;
    color: #555;
    margin-bottom: 10px;
}

.leaveTypes {
    margin-top: 10px;
}

.leaveType {
    margin-bottom: 15px;
}

.leaveTitle {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
}

.leavePoints {
    list-style-type: disc;
    padding-left: 20px;
    font-size: 14px;
    color: #444;
}

/* Mobile-friendly styles */
@media (max-width: 768px) {
    .container {
        padding: 10px;
    }

    .title {
        font-size: 20px;
    }

    .policySection {
        padding: 10px;
    }

    .policyCategory {
        font-size: 16px;
    }

    .policyDescription {
        font-size: 13px;
    }

    .leaveTitle {
        font-size: 14px;
    }

    .leavePoints {
        font-size: 13px;
    }
}
