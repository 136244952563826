.mainWrapper{
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100svh;
    width: 100%;
    z-index: 5;
}
.container {
    text-align: center;
    position: relative;
    height: 100svh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(68, 68, 68, 0.775);
}
.content{
    background-color: white;
    width: 50%;
    height: 50svh;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.title {
    color: #333;
    margin-bottom: 20px;
    font-weight: 600;
    padding: 16px 0px;
}

.button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

.button:hover {
    background-color: #0056b3;
}

.info {
    margin-top: 20px;
    font-size: 16px;
    color: #444;
}

.error {
    margin-top: 20px;
    color: red;
}

.error p {
    margin-bottom: 10px;
}

.attendanceButton{
    padding: 9px 15px;
    border: 1px solid #333;
    background-color: #444;
    color: white;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    font-weight: 600;
}


@media only screen and (max-width: 600px) {
    .container {
        position: fixed;
        z-index: 5;
        bottom: 0px;
        width: 100%;
        padding: 0px 0px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
    }
    .content{
        width: 100%;
        height: 45svh;
        border-radius: 0px;
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        -ms-border-radius: 0px;
        -o-border-radius: 0px;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
}
}