.mainWrapper{
    height: 100svh;
    width: 100%;
    background-color: #212529;
}
.mainContainer{
    height: 100svh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.logoWrapper{
    width: 100%;
    text-align: center;
}
.logoWrapper img{
    height: 60px;
}
.eachFieldWrapper{
    width: 100%;
    background-color: #ced4da;
    border: 1px solid transparent;
    overflow: hidden;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.eachFieldWrapper input{
    padding: 15px 2%;
    width: calc(96% - 2px);
    border: none;
    background-color: transparent;
    font-weight: 600;
    text-transform: lowercase;
}
.eachFieldWrapper input::placeholder{
    text-transform: capitalize;
}
.eachFieldWrapperOpen{
    width: 100%;
    background-color: #ced4da;
    border: 1px solid transparent;
    overflow: hidden;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.eachFieldWrapperOpen input{
    padding: 15px 2%;
    width: calc(96% - 2px);
    border: none;
    background-color: transparent;
    font-weight: 600;
}
.passwordWrapper{
    width: 100%;
    background-color: #ced4da;
    border: 1px solid transparent;
    overflow: hidden;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top: 1px solid #dfdfdf;
    display: flex;
    justify-content: center;
    align-items: center;
}
.passwordWrapper form{
    width: 100%;
}
.passwordWrapper input{
    padding: 15px 2%;
    width: calc(96% - 2px);
    border: none;
    background-color: transparent;
    font-weight: 600;
}
.eyeIcon{
    padding: 0px 10px;
}
.loginBtnWrapper{
    padding: 40px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loginBtnWrapper button{
    padding: 10px 24px;
    width: 140px;
    font-weight: 600;
    border-radius: 28px;
    -webkit-border-radius: 28px;
    -moz-border-radius: 28px;
    -ms-border-radius: 28px;
    -o-border-radius: 28px;
    border: none;
    cursor: pointer;
}
.loginBtnWrapperValidating{
    padding: 40px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loginBtnWrapperValidating button{
    padding: 10px 24px;
    width: 240px;
    font-weight: 600;
    border-radius: 28px;
    -webkit-border-radius: 28px;
    -moz-border-radius: 28px;
    -ms-border-radius: 28px;
    -o-border-radius: 28px;
    border: none;
    cursor: pointer;
}

.mainContent{
    width: 80%;
}
.loginTxt{
    text-align: center;
    color: #f8f9fa;
    font-weight: 600;
    padding: 20px 0px 40px;
    font-size: 1.4rem;
}
.rememberMeWrapper{
    color: #ffffff;
    padding: 40px 0px 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.rememberMeIcon{
    height: 34px;
    width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.rememberMeTxt{
    color: #ffffff;
    padding: 40px 0px 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
}