.rightColContainer{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px 0px;
}




.userDetailsWrapper{
    padding: 20px 20px;
    width: 74%;
}
.userDetailsContainer{
    background-color: #293745;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    padding: 20px;

    display: flex;
    align-items: center;
}

.userImageWrapper{
    height: 200px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.userImageWrapper img{
    height: 180px;
    width: 180px;
    border-radius: 120px;
    -webkit-border-radius: 120px;
    -moz-border-radius: 120px;
    -ms-border-radius: 120px;
    -o-border-radius: 120px;
}
.fullname{
    font-size: 1.4rem;
    font-weight: 600;
    padding: 10px 0px;
}
.designation{
    font-size: 0.9rem;
    font-weight: 600;
    color: #dfdfdf;
}
.department{
    font-weight: 600;
    padding: 10px 0px 8px;
}
.depTag{
    font-size: 0.7rem;
    background-color: #ced4da;
    padding: 4px 10px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    color: #121D24;
}
.officialEmail{
    font-weight: 600;
    font-size: 0.8rem;
}
.employeeDetailsContainer{
    margin-top: 20px;
    background-color: #293745;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    padding: 20px;

    display: flex;
    flex-direction: column;
}
.edcTitle{
    font-weight: 600;
    font-size: 0.9rem;
    padding: 0px 0px 14px 0px;
}
.edcValueWrapper{
    display: flex;
    justify-content: space-between;
    width: 100%;

}
.edcValTitle{
    font-size: 0.7rem;
    font-weight: 600;
}
.edcValTxt{
    padding: 8px 0px 0px;
    font-size: 1rem;
    font-weight: 500;
}


.statsWrapper{
    height: 70vh;
    width: 23%;
    padding: 20px 2% 20px 1%;
}

.statsContainer{
    background-color: #293745;
    width: 100%;
    height: 70vh;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;
}



.gap{
    height: 50vh;
}


@media only screen and (max-width: 600px) {
    .rightColContainer{
        flex-direction: column;
    }
    .userDetailsWrapper{
        width: calc(100% - 40px);
    }
    .userDetailsContainer{
        flex-wrap: wrap;
    }
    .gap{
        height: 2svh;
    }
    .statsWrapper{
        width: 90%;
        padding: 0px 5% !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .statsContainer{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 10%;
    }
    .edcValueWrapper{
        flex-direction: column;
    }
}