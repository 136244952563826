.mainPageWrapper{
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100%;
}

.pageWrapper{
    display: flex;
    justify-content: space-between;
    height: 100vh;
    width: 100%;
    background-color: #00000020;
}
.rightWrapper{
    overflow-y: scroll;
    background-color: white;
}

.mainWrapper{
    width: 8.27in;
    height: 11.69in;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    background-color: white;

}
.actionWrapper{
    position: fixed;
    right: 0px;
    bottom: 0px;
}

.headerWrapper{
    width: 100%;
    height: 1.17in;
    background-color: #ffcb08;
}
.firmName{
    font-weight: 700;
    text-align: center;
    font-size: 1.5rem;
    padding: 14px 0px 0px;
}
.firmAddress{
    font-size: 0.8rem;
    font-weight: 500;
    text-align: center;
}
.hrLine{
    margin: 10px 0px;
    border: 0.5px solid #202020;
}
.companyDetails{
    display: flex;
    padding: 0px 0.3in;
    justify-content: space-between;
}
.cin{
    display: flex;

}
.cinTitle{
    font-weight: 600;
}
.cinValue{
    font-weight: 600;
    color: #252525;
}
.contact{
    display: flex;
}



.contentWrapper{
    width: 100%;
    height: 10in;
}
.primaryHeadersWrapper{
    height: 1.5in;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0.5in;
}
.leftPrimaryHeader{
    width: 40%;
}
.eachPriHeader{
    display: flex;
    justify-content: space-between;
}
.rightPrimaryHeader{
    width: 53%;
}
.ephTitle{
    font-weight: 600;
    padding: 3px 0px;
}
.ephValue{
    font-weight: 600;
    padding: 3px 0px;
    color: #808080;
}


.tableContainer{
    padding: 0px 0.5in;
}
.tableHeader{
    background-color: #f1f1f1;
    border: 1px solid #202020;
    display: flex;
    justify-content: space-between;
}
.tableBody{
    justify-content: space-between;
}
.eachRowWrapper{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dfdfdf;
    border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
    font-size: 0.75rem;
}
.eachBlockHeaderTitle{
    padding: 8px 10px;
    width: 32.5%;
    border-right: 1px solid #202020;
    font-weight: 600;
}
.tableHeader .eachBlockHeader{
    padding: 8px 10px;
    width: 17.5%;
    border-right: 1px solid #202020;
    text-align: right;
    font-weight: 600;
}
.tableHeader .eachBlockHeader:last-of-type {
    padding: 8px 10px;
    width: 17.5%;
    border-right: 0px solid #202020;
    text-align: right;
    font-weight: 600;
}
.eachBlockTitle{
    padding: 8px 10px;
    width: 32.5%;
    border-right: 1px solid #dfdfdf;
    min-height: 18px;
    align-items: center;
    display: flex;
}
.eachRowWrapper .eachBlock{
    padding: 8px 10px;
    width: 17.5%;
    border-right: 1px solid #dfdfdf;
    text-align: right;
}
.eachRowWrapper .eachBlock:last-of-type {
    padding: 8px 10px;
    width: 17.5%;
    border-right: 0px solid #dfdfdf;
    text-align: right;
}

.amountWrapper{
    text-align: center;
    padding: 30px 0px 5px;
}
.finalAmt{
    font-size: 2rem;
    font-weight: 600; 
    padding: 0px 0px 10px;
}  
.finalAmtTxt{
    text-align: center;
    font-weight: 500;
    color: #808080;
    text-transform: capitalize;
}


.forLokaciWrapper{
    padding: 0px 0.5in;
    color: #808080;
    font-weight: 600;
}

.underline{
    border-bottom: 1px solid #dfdfdf;
    width: 50%;
    padding: 40px 0px;
}


.notes{
    font-size: 0.7rem;
    padding: 30px 0.5in;
}
.systemGenerated{
    text-align: center;
    font-size: 0.9rem;
    font-weight: 600;
    color: #202020;
}

.footerWrapper{
    width: calc(8.27in - 1in);
    height: 0.5in;
    background-color: #ffcb08;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 0.5in;
}
.eachFooterSection{
    height: 0.5in;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 0.8rem;
}