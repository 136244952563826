.mainWrapper {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 2.5%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 9svh;
}

/* Webview: Nav Options */
.navOptions {
    display: flex;
    gap: 20px;
}

.eachNavBtn, .eachNavBtnActive {
    text-decoration: none;
    font-size: 14px;
    color: #555;
    display: flex;
    align-items: center;
    gap: 14px;
    padding: 4px 0px;
}

.eachNavBtnActive {
    color: #000;
    font-weight: 500;
}

/* Mobile View: Hamburger Menu */
.hamburgerIcon {
    cursor: pointer;
}

.drawer {
    position: fixed;
    top: 0;
    left: -100%;
    height: 100svh;
    width: calc(80% - 0px);
    background-color: #fff;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
    transition: left 0.3s ease-in-out;
    z-index: 2000;
    padding: 0px;
}

.drawer.open {
    left: 0;
}

.drawerNav {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px 5%;
}



/* Overlay for Drawer */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100svh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}
.closeWrapper{
    height: 7svh;
    display: flex;
    align-items: center;
}
.closeBtn {
    display: flex;
    align-items: center;
    height: 7svh;
    background: none;
    border: none;
    font-size: 18px;
    color: #333;
    cursor: pointer;
    padding: 0px 2.5%;
}

.profilePicWrapper{
    width: 90%;
    height: 15svh;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 5%;
}
.profilePicContainer{
    width: 100%;
}
.profileDetailsContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.profilePicContainer img{
    height: 40px;
    width: 40px;
}
.pdName{
    font-size: 0.8rem;
    font-weight: 500;
}
.pdProfile{
    padding: 5px 0px 0px;
    font-size: 0.8rem;
    font-weight: 500;
    color: #00000080;
    text-transform: uppercase;
}