.headerWrapper{
    text-align: center;
    font-weight: 600;
    border-bottom: 1px solid #dfdfdf;
    padding: 0px 0px 10px;
}


@media only screen and (max-width: 600px) {
    .headerWrapper{
        padding: 0px;
        height: 7svh;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        color: #353535;
        font-size: 13px;
        letter-spacing: 1px;
        background-color: #dfdfdf;
    }


}