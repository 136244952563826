.noticesWrapper{
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    padding: 10px;
    background-color: #adb5bd;
}


.annoucementTitle{
    display: flex;
    align-items: center;
    font-weight: 600;
    color: #495057;
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 10px;
}
