.mainWrapper{
    width: 100%;

}
.mainContent{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.leftWrapper{
    height: 93vh;
    width: 22.5%;
}
.leftContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 88%;
    padding: 0px 6%;
}

.eachDocNav{
    text-align: left;
    padding: 14px 10px;
    width: calc(100% - 22px);
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    border: 1px solid transparent;
    margin-bottom: 10px;
}
.eachDocNav:hover{
    border: 1px solid #dfdfdf;
}

.eachDocNavActive{
    text-align: left;
    padding: 14px 10px;
    width: calc(100% - 22px);
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    border: 1px solid transparent;
    background-color: #DFE2E6;
    margin-bottom: 10px;
}
.eachDocNavActive:hover{
    border: 1px solid #dfdfdf;
}