.mainHeader{
    display: flex;
    justify-content: space-between;
    padding: 9px 1%;

}



.tableHeader{
    display: flex;
    
}