.holidaysCalendar {
    display: flex;
    flex-direction: column;
    padding: 16px;
    background-color: #f9f9f9;
    font-family: 'Arial', sans-serif;
}

.monthContainer {
    margin-bottom: 20px;
    background: white;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.monthTitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
    text-transform: capitalize;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 5px;
}

.holidayItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 10px;
    border-bottom: 1px solid #dfdfdf;
    font-size: 14px;
}

.holidayItem:last-child {
    border-bottom: none;
}

.holidayDate {
    font-weight: bold;
    color: #007bff;
}

.holidayName {
    color: #555;
    font-style: italic;
}

.noHolidays {
    text-align: center;
    color: #888;
    font-size: 14px;
    padding: 10px 0;
}

/* Mobile-friendly styles */
@media (max-width: 768px) {
    .monthContainer {
        padding: 8px;
        margin-bottom: 15px;
    }

    .monthTitle {
        font-size: 16px;
    }

    .holidayItem {
        font-size: 13px;
    }

    .holidayDate {
        font-size: 14px;
    }
}
