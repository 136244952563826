.formWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: 91vh;
    gap: 1.5%;
}
.credentialsWrapper{
    height: 91vh;
}
.credentialsHeader{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 1%;
}
.credentialsHeader button{
    background-color: #DFE2E6;
    border: 1px solid #343A40;
    color: #343A40;
    padding: 6px 10px;
    font-weight: 600;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.credentialsBody{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 1.5%;
    height: 80vh;
}
.eachBankCredential{
    min-height: 26vh;
    min-width: 30%;
    background-color: #DFE2E6;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 1%;
    color: black;
}
.bank{
    font-weight: 600;
    font-size: 0.8rem;
}
.bankAddress{
    font-weight: 500;
    font-size: 0.7rem;
    color: #808080;
}
.accountHolderName{
    background-color: #666666;
    font-size: 1.3rem;
    font-weight: 600;
    text-transform: uppercase;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    color: transparent;
    text-shadow: rgba(245,245,245,0.5) 3px 5px 1px;
}
.accountNumber{
    padding: 14px 0px;
    font-size: 2rem;
    letter-spacing: 3px;
    font-family: 'Rubik';
    font-weight: 600;
}
.ifscContainer{
    width: 35%;
    background-color: #DFE2E6;
    padding: 40px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    height: 50vh;
}
.ifscContent{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.formContainer{
    width: 35%;

    background-color: #DFE2E6;
    padding: 40px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;

    height: 50vh;
}

.eachFieldWrapper{
    width: 100%;
}
.eachFieldWrapper input{
    padding: 7px 10px;
    width: calc(100% - 22px);
}
.ifscWrapper{
    width: 70%;
}
.ifscWrapper input{
    padding: 7px 10px;
    width: calc(100% - 22px);
}

.getBtnWrapper{
    width: 28%;
    display: flex;
    align-items: center;
}
.getBtnWrapper button{
    display: flex;
    width: 100%;
    padding: 8px 10px;
    background-color: #343A40;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    color: white;
    font-weight: 600;
}
.validated{
    display: flex;
    width: 100%;
    padding: 8px 10px;
    background-color: green !important;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    color: white;
    font-weight: 600;
}


.ifsc{
    text-transform: uppercase;
}

.twoInARow{
    padding: 10px 0px;
    display: flex;
    justify-content: space-between;
}

.fieldCaption{
    font-weight: 600;
    font-size: 0.8rem;
    color: #808080;
    padding: 0px 0px 4px;
}
.bankNameWrapper{
    width: 100%;
    padding: 8px 0px;
}
.bankNameWrapper input{
    padding: 7px 10px;
    width: calc(100% - 22px);
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.bankAddress{
    width: 100%;
    padding: 8px 0px;
}
.bankAddress textarea{
    padding: 7px 10px;
    width: calc(100% - 22px);
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.eachField{
    width: 47%;
}
.eachField input{
    padding: 7px 10px;
    width: calc(100% - 22px);
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}



.bankAccountNumberWrapper{
    width: 100%;
    padding: 10px 0px;
}
.bankAccountNumberWrapper input{
    padding: 8px 10px;
    width: calc(100% - 22px);
    font-weight: 600;
    letter-spacing: 1.2px;
    text-transform: uppercase;
}
.accountHolderName{
    width: 100%;
}
.accountHolderName input{
    padding: 8px 10px;
    width: calc(100% - 22px);
    font-weight: 600;
    letter-spacing: 1.2px;
    text-transform: uppercase;
}

.pancardWrapper{
    width: 100%;
    padding: 10px 0px;
}
.pancardWrapper input{
    padding: 8px 10px;
    width: calc(100% - 22px);
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
}



.actionWrapper{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    gap: 10px;
}
.cancelBtn{
    padding: 7px 14px;
}
.submitBtn{
    padding: 7px 14px;
}


@media only screen and (max-width: 600px) {
    .credentialsBody{
        flex-direction: column;
        padding: 20px 4%;
    }
    .eachBankCredential{
        width: 94%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0px 3%;
    }
    .accountNumber{
        font-family: 'Montserrat';
    }


    .ifscContainer{
        width: 80%;
        padding: 20px 5%;
    }
    .formWrapper{
        flex-direction: column;
        padding: 10svh 0px;
    }
    .formContainer{
        width: 80%;
        padding: 20px 5%;
    }

}