
.birthdayTodayWrapper {
    border-bottom: 1px solid #dfdfdf;
    background-color: #f8f9fa;
}

.btTitle {
    text-align: center;
    font-weight: 600;
    padding: 10px 0px;
    color: #495057;
    font-size: 0.8rem;
}

.heroname {
    font-size: 1.6rem;
    font-weight: 600;
    text-align: center;
    width: 100%;
}

.tagsWrapper {
    display: flex;
    justify-content: center;
    font-size: 0.6rem;
    gap: 10px;
    padding: 8px 0px 0px;
}
.tagsWrapperSmall{
    display: flex;
    justify-content: flex-start;
    font-size: 0.6rem;
    gap: 10px;
    padding: 8px 0px 0px;
}

.tagsWrapperSmall .eachTag{
    font-size: 0.5rem;
    font-weight: 500;
}


.eachTag {
    border: 1px solid #dfdfdf;
    padding: 4px 10px;
    font-weight: 0.6rem;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.writeOnTimeline {
    padding: 8px 3%;
}
.writeOnTimeline textarea{
    margin: 8px 0px;
}

.writeOnTimeline textarea {
    width: calc(90% - 2px);
    background-color: #e9ecef;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    padding: 10px 2%;
    border: 1px solid #ced4da;
    min-height: 100px;
    max-height: 340px;
    min-width: calc(90% - 2px);
    max-width: calc(90% - 2px);
}

.profileUsername {
    display: flex;
    justify-content: space-between;
    width: 88%;
}

.userIconWrapper {
    width: 60px;
    height: 60px;
    align-items: center;
    display: flex;
    justify-content: center;
    background-color: #dfdfdf;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.userDetailsWrapper {
    padding: 0px 1.5%;
    width: 97%;
}

.personName {
    font-weight: 600;
    color: #343140;
    padding: 4px 0px 0px 0px;
}