
.eachComingBirthdays {
    padding: 6px 3%;
    border-bottom: 1px solid #dfdfdf;
}

.eachComingBirthdays:hover {
    background-color: #f8f9fa;
    cursor: pointer;
}

.upperRowWrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.profileUsername {
    display: flex;
    justify-content: space-between;
    width: 88%;
    align-items: center;
}
.userIconWrapper{
    width: 15%;
}
.userIconContainer {
    width: 60px;
    height: 60px;
    align-items: center;
    display: flex;
    justify-content: center;
    background-color: #dfdfdf;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.userDetailsWrapper {
    padding: 0px 1.5%;
    width: 82%;
}

.personName {
    font-weight: 600;
    color: #343140;
    padding: 4px 0px 0px 0px;
}

.tagsWrapperSmall{
    display: flex;
    justify-content: flex-start;
    font-size: 0.6rem;
    gap: 10px;
    padding: 8px 0px 0px;
}
.tagsWrapperSmall .eachTag{
    font-size: 0.5rem;
    font-weight: 500;
}


.eachTag {
    border: 1px solid #dfdfdf;
    padding: 4px 10px;
    font-weight: 0.6rem;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}


.birthValueWrapper {
    text-align: center;
    width: 12%;
}

.vsTxt {
    font-size: 0.6rem;
}

.sTxt {
    font-weight: 600;
    font-size: 1rem;
}

.daysUntilNextBirthday {
    font-size: 2rem;
    font-weight: 600;
}



@media only screen and (max-width: 600px) {
    .userIconContainer {
        height: 44px;
        width: 44px;
        border-radius: 44px;
        -webkit-border-radius: 44px;
        -moz-border-radius: 44px;
        -ms-border-radius: 44px;
        -o-border-radius: 44px;
}
    .personName{
        font-size: 0.8rem;
    }
    .daysUntilNextBirthday{
        font-size: 1.2rem;
    }

}