.mainWrapper{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.mainWrapper img{
    width: 200px;
    height: auto;
}

@media only screen and (max-width: 600px) {
    .mainWrapper img{
        width: 140px;
        height: auto;
    }
    
}