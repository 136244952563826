.mainWrapper{
    width: 100%;
}
.mainContainer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.leftColWrapper{
    width: 6%;
    height: 91vh;
    background-color: #293745;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: space-between;
    position: fixed;
    top: 9vh;
    left: 0px;
}

.rightColWrapper{
    width: 94%;
    background-color: #121D24;
    color: white;
    padding-left: 6%;
}

.logoutWrapper{
    display: flex;
    justify-content: center;
}
.eachNavWrapper{
    display: flex;
    justify-content: center;
    margin: 10px 0px;
}
.logoutBtn{
    height: 48px;
    width: 48px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    padding: 6px;
}
.logoutBtn:hover{
    border: 1px solid #ffffff;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.eachNav{
    min-height: 48px;
    width: 48px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    padding: 6px;
    border-bottom: 3px solid transparent;
}
.eachNav:hover{
    border-bottom: 3px solid #ffffff;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
}
.textIcon{
    font-size: 0.7rem;
    font-weight: 500;
}

.logoutWrapper{
    padding: 120px 0px;
}




@media only screen and (max-width: 600px) {
    .mainWrapper{
        height: 82svh;
    }
    .mainContainer{
        height: 82svh;
    }
    .leftColWrapper{
        bottom: 0px;
        display: flex;
        width: 100%;
        height: 9svh;
        top: auto;
        position: fixed;
        padding: 0px;
        margin: 0px;
        flex-direction: row;
    }
    .upperColWrapper{
        display: flex;
        flex: 8;
        padding: 6px 10px;
    }
    .lowerColWrapper{
        flex: 2;
    }
    .rightColWrapper{
        width: 100%;
        padding: 0px;
        padding-left: 0px;
        height: 84svh;
        overflow-y: scroll;
    }
    .eachNavWrapper{
        margin: 0px;
        align-items: center;
        width: 66px !important;
        height: 56px !important;
        padding: 0px;
    }
    .eachNav{
        width: 60px !important;
        height: 50px !important;
        padding: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .textIcon{
        padding: 0px;
        margin: 0px;
    }
    .logoutWrapper{
        padding: 0px;
        margin: 0px;
        display: flex;
        align-items: center;
        width: 66px !important;
        height: 66px !important;
    }

}