.headerWrapper{
    width: 100%;
    height: 9svh;
    background-color: #343a40;
}
.contentWrapper{
    width: 100%;
    background-color: #f8f9fa;
}



@media only screen and (max-width: 600px) {
    .contentWrapper{
        width: 100%;
        background-color: #f8f9fa;
        height: 91svh;
    }
}