.mainWrapper{
    height: 100svh;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 4000;
}
button{
    cursor: pointer;
}
.mainContainer{
    width: 100%;
    height: 100svh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0 0 0 / 80%)
}
.mainContent{
    width: 55%;
    height: 65svh;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.mainHeader{
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    justify-content: space-between;
    padding: 11px 2%;
    font-weight: 600;
    align-items: center;
}
.optTimeWrapper{
    display: flex;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    overflow: hidden;
}
.eachOptTime{
    border: none;
    background-color: transparent;
    font-weight: 600;
    font-size: 0.8rem;
    padding: 8px 20px;
}
.eachOptTimeActive{
    border: none;
    background-color: green;
    font-weight: 600;
    font-size: 0.8rem;
    padding: 8px 20px;
    color: white;
}

.markAttendanceWrapper{
    height: 40svh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.dialWrapper{
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}
.eachDialWrapper{
    padding: 20px;
}
.eachDialWrapper select{
    font-size: 2.8rem;
    width: 100px;
    text-align: center;
    border: none;
    appearance: none;
    cursor: pointer;
}
select::placeholder{
    font-size: 1rem;
}
select:focus{
    font-size: 1rem;
}
.eachDialWrapper option{
    font-size: 1rem !important;
}

.msgTxt{
    font-size: 0.8rem;
    font-weight: 600;
    padding: 40px 25%;
    text-align: center;
    color: #343A4060;
}
.checkInBtnWrapper{
    text-align: center;
}
.timeErrorMsg{
    font-size: 0.7rem;
    color: red;
    padding: 4px 0px 10px;
    font-style: italic;
}
.checkInBtn{
    padding: 12px 20px;
    min-width: 160px;
    border: 1px solid #343A40;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    font-weight: 600;
    background-color: #343A40;
    color: #dfdfdf;
    font-size: 1rem;
    cursor: pointer;
}

.alreadyClockedInWrapper{
    width: 100%;
    border-bottom: 1px solid #dfdfdf;
}
.aciwRowHeader{
    display: flex;
    justify-content: space-between;
    padding: 6px 10px;
    font-weight: 600;
    color: #343A4087;
    background-color: #F1F4F9;
}
.aciwRow{
    display: flex;
    justify-content: space-between;
    padding: 6px 10px;
    font-size: 0.7rem;
    font-weight: 600;
    color: #343A4087;
}
.date{
    width: 15%;
}
.day{
    width: 15%;
}
.clockIn{
    width: 12%;
}
.dayStatus{
    width: 8%;
}
.approvalStatus{
    width: 20%;
}

.attendanceTxt{
    text-align: center;
    color: green;
    font-weight: 600;
    padding: 0px 0px 20px;
}
.doneBtn{
    padding: 9px 28px;
    border: 1px solid #343A40;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    background-color:#343A40;
    color: white;
}
.aciwRowHeaderMob{
    display: none;
}


@media only screen and (max-width: 600px) {
    .mainContent{
        width: 85%;
        height: 75svh;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
    }
    .aciwRowHeaderMob{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .aciwRowHeader{
        display: none;
    }
    .aciwRow{
        display: none;
    }
    .aciwRowHeaderMob .date{
        width: 48%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-right: 1px solid #dfdfdf;
    }
    .aciwRowHeaderMob .day{
        width: 48%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-left: 1px solid #dfdfdf;
    }
    .aciwRowHeaderMob .clockIn{
        width: 48%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: 1px solid #dfdfdf;
    }
    .aciwRowHeaderMob .dayStatus{
        width: 48%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-left: 1px solid #dfdfdf;
        border-right: 1px solid #dfdfdf;
    }
   
    .aciwRowHeaderMob .approvalStatus{
        width: 48%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-left: 1px solid #dfdfdf;
    }
    .msgTxt{
        padding: 10px;
    }
    .title{
        font-weight: 500;
        font-size: 0.7rem;
        padding: 6px 0px;
        text-transform: uppercase;
    }
    .status{
        font-weight: 600;
        font-size: 0.8rem;
        padding: 0px 0px 10px;
    }

}