.mainWrapper{
    padding: 10px 10px;
    background-color: #f8f9fa;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    margin: 8px 0px 0px 0px;
}

.newTag{
    height: 14px;
}
.announcement{
    font-size: 0.7rem;
    text-align: justify;
    font-style: italic;
    font-weight: 500;
    color: #495057;
    padding: 8px 0px;
}